.import-body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.import-logo-container {
  cursor: pointer;
  transition: 0.2s ease all;
  display: flex;
  justify-content: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-blend-mode: darken;
  transition: ease all 0.2s;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 3px;
  margin: 3px;
  border: 1px solid transparent;
  @media only screen and (max-width: 768px) {
    margin: 3px;
  }
}

.import-selection-container {
  display: flex;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.sub-header {
  font-family: "ShareTechRegular";
}

.manual-import-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  label {
    font-family: "ShareTechRegular";
  }
}
.csv-logo-container {
  cursor: pointer;
  padding: 5px;
}

.intructions-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    
  }
  @media only screen and (max-height: 840px) {
    height: 75vh;
    padding-bottom: 10px;
  }

  ul {
    display: flex;
    height: fit-content;
    justify-content: space-evenly;
    flex-direction: column;
    list-style: none;
    padding: 5;
    margin: 0;
    li {
      display: block;
    }
  }
  .instruction-label {
    font-family: "OpenSans";
    padding-left: 5px;
  }
}
.intructions-container-csv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (max-height: 840px) {
    height: 75vh;
    padding-bottom: 10px;
  }
  ul {
    display: flex;
    height: fit-content;
    justify-content: space-evenly;
    flex-direction: column;
    list-style: none;
    padding: 5;
    margin: 0;
    li {
      display: block;
    }
  }
  .instruction-label {
    font-family: "OpenSans";
    padding-left: 5px;
  }
}
.intructions-container-special {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  height: 100%;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    height: fit-content;
    padding: 5px;
    li {
      display: block;
    }
  }
  .instruction-label {
    font-family: "OpenSans";
    padding-left: 5px;
  }
}

.security-popover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  label {
    font-family: "ShareTechRegular";
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
.popover-trigger {
  cursor: pointer;
}

/* .notice-container {
  margin-bottom: auto;
} */

.drawer-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: "ShareTechRegular";
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  .drawer-logo-container {
    display: flex;
    justify-content: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-blend-mode: darken;
    transition: ease all 0.2s;
    padding: 10px;
  }
}

.submit-api-button-container {
  padding: 5px;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.platform-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.dropzone-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.popover-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  font-family: "ShareTechRegular";
  font-size: 14px;
}

.input-field-hash {
  min-width: 350px;
}
.wallet-options-container {
  display: flex;
  border: 1px solid red;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: "ShareTechRegular";
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.wallet-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 20px;
  height: 30%;
}

.manual-transaction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "ShareTechRegular";
  font-size: 14px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-height: 840px) {
    height: 75vh;
    padding-bottom: 10px;
  }
  

  .manual-transaction-select-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .selected-account-badge {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    text-transform: none !important;
    //transform: translateY(120%);
    justify-content: space-between;
    padding-left: 15px;
    width: fit-content;

    .selected-account-badge-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      cursor: pointer;
    }
  }
}

.manual-transaction-submit-button-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}
.checkmark {
  transition: 0.3s ease all;
  color: transparent;
}
.checkmark-valid {
  transition: 0.3s ease all;
  color: green;
}

.custom-account-exchange-popup-container {
  display: flex;
  justify-content: center;
}

.platform-logo-container {
  transition: 0.2s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
}


.wallet-import-control-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  gap: 10px;
}