.inspected-transactions-header-container {
  width: 100%;
  height: 10%;
  min-height: 70px;
  display: flex;
  flex-direction: row;

  .back-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    opacity: .8;
  }

  .inspected-platform-badge {
    margin-left: auto;
  }
}