$expanded-button-width: 150px;
.multi-button-container-expanded {
  transition: 0.2s ease all;
  height: 30px;
  width: 150px;
  border-radius: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.multi-button-container {
  transition: 0.2s ease all;
  height: 30px;
  width: 80px;
  border-radius: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    width: 150px;
    .compact-button {
      //hide the button
      transform: scale(1);
    }
  }

  .multi-button-idle {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.button-container-row {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  height: 80%;
  width: 95%;
  gap: 5px;
}

.compact-button,
.compact-button-expanded {
  //hide the button
  transition: 0.2s ease all;

  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  border-radius: 15%;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.compact-button {
  transform: scale(0);
}

.compact-button-expanded {
  opacity: 0.8;
}

#transaction-edit-shortcut-button {
  background-color: rgb(68, 90, 218, 0.5);
  height: 100%;
  width: 100%;
}

#transaction-save-shortcut-button {
  background-color: rgb(68, 218, 90, 0.5);
  height: 100%;
  width: 100%;
}

#transaction-delete-shortcut-button {
  height: 100%;
  width: 100%;
  background-color: rgb(244, 79, 98, 0.5);
}

#transaction-delete-confirm-button {
  height: 100%;
  width: 100%;
  background-color: rgb(244, 79, 98, 0.5);
}

.quick-edit-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
}

.special-transaction-type-tooltip {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-menu-item {

  transition: .2s ease all;

  &:hover {
    color: rgb(244, 79, 98, 0.5)
  }
}

#transaction-unlink-shortcut-button {
  width: 100%;
  height: 100%;
  border: 1px solid;
}