.body-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
  height: 100%;
}

.body-lower-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.body-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.body-container-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.dashboard-body-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 10px;
}

.ring-progress-center {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-family: "ShareTechRegular";
}

.logo-container {
  display: flex;
  justify-content: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-blend-mode: darken;
  transition: ease all 0.2s;
  padding: 2px;
  align-items: center;
}

.dashboard-body-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  label {
    font-family: "ShareTechRegular";
    text-align: left;
  }

  .dashboard-exchange-column {
    display: flex;
    margin: 2px;
  }
}

/* .generated-reports-container {
  transition: 0.2s ease all;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  gap: 3px;
  justify-content: flex-start;

  
}
 */
.integrations-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px;

  .integrations-container {
    max-width: 300px;
    min-width: 300px;
    // media query
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      min-width: 100%;
    }

    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;

    
    .platform-columns {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 5px;
      width: 100%;
      height: 100%;
      padding: 5px;
    }
  }
}
.divider {
  width: 100%;
  height: 1px;
}

.popover-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.info-label {
  // break-word
  word-wrap: break-word;
  font-size: smaller;
  max-width: 100px;
}