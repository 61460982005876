.language-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

label {
  font-family: "ShareTechRegular";
}

.application-settings-control-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.application-settings-control-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.user-settings-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .left-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    min-width: 200px;
    max-width: 300px;
    height: 100%;
    border-radius: 5px;
    // media query
    @media only screen and (max-width: 768px) {
      width: 50px;
      min-width: 50px;
      max-width: 50px;
    }

    .footer {
      margin-top: auto;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .footer-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .item-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    height: 100%;
    flex: 1;
  }
}

.user-account-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}

.plan-title {
  font-size: 1.2rem;
}

.payment-container {
  display: flex;
  justify-content: center;
}

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: large;
  z-index: 99;
  width: 100%;
  margin-top: auto;
}
.user-account-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  .account-settings-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 200px;
  }
}

.content-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  width: 100%;
  height: 100%;
  // media query for mobile
  @media (max-width: 1282px) {
    transform: translateY(50px);
  }
}

.membership-content-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .memberships-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 5px;

    .membership-plans-container,
    .current-membership-plan-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      gap: 1.5%;
      // media query for mobile
      @media (max-width: 1282px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        scale: 0.8;
      }

      .membership-plan-container {
        min-width: 200px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .membership-plan-header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 40px;
          font-size: large;
        }

        .membership-plan-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          padding: 10px;
        }

        .membership-plan-footer {
          margin-bottom: auto;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.memberships-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .membership-card {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.invoices-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .invoices-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    height: 40px;
    padding: 5px;
  }

  .invoices-scrollable-body {
    display: flex;
    flex-direction: column;
    width: 98%;
    height: 70%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .invoice-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    height: 40px;
    padding: 5px;
    font-size: smaller;
    flex: 1;
    // media query for mobile
    @media (max-width: 768px) {
      font-size: x-small;
    }

    .download-button {
      font-size: medium;
      height: 100%;
      width: 100px;
      //media query for mobile
      @media (max-width: 1282px) {
        width: 50px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
}

.package-version-label {
  opacity: 0.7;
  font-size: xx-small;
}
