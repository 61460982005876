body {
  margin: 0;
  padding: 0;
}

.header {
  font-size: 36px;
  font-family: "ShareTechRegular";
  font-size: large;
  @media only screen and (max-width: 768px) {
    font-size: 26px;
  }
}

.page-container {
  padding-top: 4.8rem;
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.page-card-container {
  margin-top: 1rem;
  margin-bottom: auto;
  transition: 0.2s ease all;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 85vh;
  width: 90vw;
  border-radius: 5px;
  border: 1px solid;
  padding: 2rem;
  @media only screen and (max-width: 1980px) {
    padding: 1rem;
  }
  @media only screen and (max-height: 840px) {
    height: 75vh;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 70vh;
  }
}

.landing-page-background-image-container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
