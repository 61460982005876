.menu-item-container, .menu-item-container-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  cursor: pointer;
  
  transition: 0.2s;
  * {
    cursor: pointer;
  }
  .menu-item-container-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 80%;
    padding: 0 10px;
    margin: 5px;
    border-radius: 5px;
    // media query
    @media only screen and (max-width: 768px) {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin: 0px;
    }
  }
}

#menu-item-container-footer {
  width: 99.5%;
  border-left: 1px solid transparent;
}
