.etherscan-logo {
  display: flex;
  width: 100%;
  height: 100px;
  //scale background image
  background-size: 40% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-blend-mode: darken;
  transition: ease all 0.2s;
  
}

.etherscan-api-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
}