.inspected-transaction-item-container {
  flex: 1;
  overflow: hidden;

  .inspected-transaction-item-header-container {
    border-bottom-right-radius: 16px;
    width: 87vw;
    position: fixed;
    display: flex;
    align-items: center;
    padding: 5px;
    height: 50px;
    min-height: fit-content;
    z-index: 2;
    backdrop-filter: blur(2px);
    .right-section {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
    }
    .left-section {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-right: auto;
      flex: 1;
      gap: 15px;
      .row-item-date-container {
        height: 100%;
        font-size: smaller;
      }
      // media query for mobile

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
      }
    }
  }
  .inspected-transaction-item-body-container, .inspected-transaction-item-body-container-scrolling {
    transition: .2s ease all;
    margin-top: 50px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
