.compact-report-header-container {
  height: 100px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  .report-center-section-container {
    display: flex;
    .compact-center-buttons {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 10px;

      .compact-transactions-submit-button {
        border-radius: 5%;
        height: 80px;
        width: 80px;
        //media query
        @media only screen and (max-width: 620px) {
          height: 70px;
          width: 70px;
        }
        align-items: center;
        display: flex;
        font-size: smaller;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .compact-filter-popover-target {
    flex: 1;
    margin-right: 10px;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: smaller;
  }
}

.compact-submit-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: smaller;
}

.reset-filters-container {
  position: absolute;
  transform: translate(50%, 20px);
  transition: 0.2s ease all;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
}