body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #000;
}

.bar-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.line-type1 {
  color: transparent;
  opacity: 0.2;
  border-right: 2px solid;
  height: 90px;
  align-self: center;
  justify-self: center;
  transform: translate(5px, -5px);
  position: fixed;
  animation: line-loader1 1.2s linear infinite;
}
.line-type2 {
  color: transparent;
  opacity: 0.2;
  border-right: 2px solid;
  height: 90px;
  align-self: center;
  justify-self: center;
  transform: translate(5px, -5px);
  position: fixed;
  animation: line-loader2 1.2s linear infinite;
}

.loading-label {
  top: 100%;
  left: 25%;
  right: 25%;
}

.bar-type1 {
  width: 10px;
  height: 70px;
  background: transparent;
  display: inline-flex;
  margin: 0 2px;
  animation: loader1 1.2s linear infinite;
}
.bar-type2 {
  width: 10px;
  height: 70px;
  background: transparent;
  display: inline-flex;
  margin: 0 2px;
  animation: loader2 1.2s linear infinite;
}
.bar1,
.line1 {
  animation-delay: 0.1s;
}
.bar2,
.line2 {
  animation-delay: 0.2s;
}
.bar3,
.line3 {
  animation-delay: 0.3s;
}
.bar4,
.line4 {
  animation-delay: 0.4s;
}
.bar5,
.line5 {
  animation-delay: 0.5s;
}
.bar6,
.line6 {
  animation-delay: 0.6s;
}
.bar7,
.line7 {
  animation-delay: 0.7s;
}
.bar8,
.line8 {
  animation-delay: 0.8s;
}

@keyframes loader1 {
  0% {
    transform: scaleY(0.1);
    background: transparent;
  }
  50% {
    transform: scaleY(1);
    background: var(--loader-color1);
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}

@keyframes loader2 {
  0% {
    transform: scaleY(0.1);
    background: transparent;
  }
  50% {
    transform: scaleY(1);
    background: var(--loader-color2);
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}

@keyframes line-loader1 {
  0% {
    background: transparent;
  }
  50% {
    background: var(--loader-color1);
  }
  100% {
    background: transparent;
  }
}

@keyframes line-loader2 {
  0% {
    background: transparent;
  }
  50% {
    background: var(--loader-color2);
  }
  100% {
    background: transparent;
  }
}
