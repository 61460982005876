.transaction-data-preview {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: flex-start;
  min-width: fit-content;
  font-size: smaller;
  backdrop-filter: 5px;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);

}

.transaction-type-label {
  // add subtle text shadow
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .25s ease-in;
  font-size: large;
  font-weight: 100;
  font-family: "ShareTechRegular";
  text-transform: uppercase;
  padding: 4px;
  min-width: 100px;
  text-align: center;
  border-radius: 5%;
  opacity: .9;
  // media queries
  @media only screen and (max-width: 968px) {
    font-size: smaller;
    min-width: 80px;
  }
  @media only screen and (max-width: 868px) {
    min-width: 70px;
  }
  @media only screen and (max-width: 768px) {
    min-width: 60px;
  }

}

.deposit-type-container, 
.trade-type-container,
.withdrawal-type-container,
.cancelled-transaction-type-container {
  max-width: 300px;
  width: fit-content;
  height: 90%;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px;
}