.platform-badge-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;

  .account-name-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
    text-align: center;
  }
  .compact-account-name-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
    text-align: center;
    position: fixed;
  }
  // media query for mobile
  @media only screen and (max-width: 768px) {
    
    padding: 10px;
  }
}

.platform-badge-tooltip-label {
  display: flex;
  flex-direction: column;
}
