.account-body-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    justify-content: flex-start;
    gap: 5px;
  }
}

.account-body-controls-container {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    height: 80px;
    flex-direction: column;
    justify-content: space-between;
  }
}

.account-controls-container {
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media only screen and (max-width: 768px) {
    width: 80px;
    justify-content: space-between;
  }
}
.account-deletion-confirmation-modal-button-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
}

.accounts-table-footer {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  font-size: smaller;
}

.accounts-table-container {
  border: 1px solid;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  justify-content: flex-start;
  padding-top: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.accounts-table-row {
  transition: ease all 0.1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  height: 50px;
  border: 1px solid;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: space-between;
    width: inherit;
    height: 24px;
    border: 1px solid;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 10px;
  }
}
.account-controls-row-container {
  padding: 10px;
}
.account-name-container {
  transition: 0.1s ease all;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  font-size: 17px;
  @media only screen and (max-width: 1068px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 968px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 768px) {
    width: 50%;
    font-size: 9px;
  }
  .copy-label {
    cursor: pointer;
  }
}

.transaction-count-container {
  font-size: 17px;

  @media only screen and (max-width: 1068px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 968px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
}

.edit-account-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .form-container {
    display: flex;
    flex-direction: column;
    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .text-overlay {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        height: 40px;
        z-index: 5;
        position: fixed;
        width: 90%;
        transform-origin: bottom left;
        opacity: 1;
        transition: 0.5s ease all;
        transform: translateY(25px);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .api-input-container {
        display: flex;
        flex-direction: row;
        .api-input {
          input {
            transition: 0.2s ease all;
            cursor: pointer;
            opacity: 0.8;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
