.form-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: fit-content;
  align-items: center;
}
.register-form-container {
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 700px;
  max-width: 700px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    width: 100%;
  }
}



.row-container, .mobile-row-container {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .input-field {
    width: 300px;
  }

  .checkmark {
    transition: 0.3s ease all;
    color: transparent;
  }
  .checkmark-valid {
    transition: 0.3s ease all;
    color: green;
  }
}
.mobile-row-container {
  flex-direction: row;
  width:100%;
  justify-content: center;
  align-items: center;
  .input-field {
    width: 150px;
  }
}

.submit-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.terms-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .terms-body-container {
    font-size: smaller;
  }
}
