.error-page-container {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  .error-card {
    display: flex;
    flex-direction: column;
    min-height: 50%;
    max-height: 60lvh;
    width: 80vw;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
    padding: 20px;
    justify-content: space-between;
    .error-header,
    .error-body,
    .error-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .error-header {
      font-size: larger;
    }
    .error-body {
      font-size: smaller;
    }
  }
}
