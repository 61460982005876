.report-body-container {
  border: 1px solid;
  display: flex;
  border-radius: 2px;
  padding: 1px;
  align-self: center;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 95%;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 99%;
  }
}

.report-scrollable-body-container {
  height: 90%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.report-pagination-header-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  height: 10%;
  //box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  .report-pagination-header-range-container {
    display: flex;
    flex-direction: column;

    width: 200px;
    // media query {
    @media only screen and (max-width: 968px) {
      width: fit-content;
      gap: 3px;
    }

    .report-pagination-header-range-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: smaller;
      align-items: center;
      opacity: 0.7;
      .report-pagination-header {
        font-size: medium;
        @media only screen and (max-width: 1150px) {
          font-size: smaller;
        }
      }
    }
  }
}

.upload-bar-container {
  height: 1px;
  position: absolute;
  .upload-bar {
    border: 1px solid transparent;
    transition: 1s ease all;
    border-radius: 5px;
    height: 100%;
  }
}

.page-loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* .platform-badge-container {
  min-width: 6rem;
  // media query {
  @media only screen and (max-width: 968px) {
    min-width: fit-content;
  }
}
 */
