.manual-transaction-in-input-container,
.manual-transaction-out-input-container {
  border: 1px solid rgba($color: #363537, $alpha: 0.3);
  border-radius: 2px;
  padding: 10px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.manual-transaction-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.manual-transaction-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  .manual-transaction-header {
    font-size: 20px;
    padding: 5px
  }
}
.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
