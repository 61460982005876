.voucher-content {
  display: flex;
  flex-direction: column;
  height: 90%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  padding: 10px;
}

.price-plan-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .price-plan-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    label {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .price-plan-prices-row {
    display: flex;
  }
  .discount-label-container {
    display: flex;
  }

}
#old-value {
  text-decoration: line-through;
  opacity: .5;
}
#new-value {
  animation: NewValueHighlight 3s ease-out;
  filter: grayscale(100%);
}

.voucher-input-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  .voucher-input {
    border: none;
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
    width: 100%;
    height: 37px;
    border-radius: 5px;
    outline: none;
    font-family: "ShareTechRegular";
    // hide controls
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    // hide arrows
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.error-label-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.voucher-applied-container {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 2px;
}

.checkout-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkout-label-container {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.checkout-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  flex-direction: column;
  gap: 20px;
}
