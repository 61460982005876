//define sass variables
$report-row-item-height: 40px;
$expanded-report-row-item-height: 500px;
$narrow-expanded-report-row-item-height: 600px;
$expanded-box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 2px, rgba(0, 0, 0, 0.09) 0px 2px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px,
  rgba(0, 0, 0, 0.09) 0px 8px 16px, rgba(0, 0, 0, 0.09) 0px 16px 32px, rgba(0, 0, 0, 0.09) 0px 32px 64px;

.transaction-history-item-popup-container {
  transition: 0.4 ease all;
  height: ($expanded-report-row-item-height);
  justify-content: flex-start;

  .transaction-item-container-upper-container {
    flex-direction: row;
    display: flex;
  }
}
.id-container {
  width: 50px;
  opacity: 0.8;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &:hover {
    opacity: 1;
  }
}

#popup-item {
  border-color: transparent;
  box-shadow: none !important;
}

.transaction-item-container,
.transaction-item-container-deleted,
.transaction-item-container-expanded {
  position: relative;
  flex-direction: column;
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: 98%;
  height: 50px;
  border: 2px solid;
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin: 2px;
  padding: 2px;
  transition: 0.4 ease all;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    height: 50px;
  }

  @media only screen and (max-width: 1168px) {
    .expanded-body-container {
      transition: 0.2s ease all;
      width: 100%;
      justify-content: center;
      * {
        font-size: 93%;
      }
    }
  }
  .transaction-item-container-upper-container {
    flex-direction: row;
    display: flex;
    /* @media only screen and (max-width: 1168px) {
      justify-content: space-evenly;
  
    } */
  }
}

.transaction-item-container {
  animation: collapse 0.15s ease;
  animation-fill-mode: forwards;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.transaction-item-container-deleted {
  opacity: 0.3;
  cursor: not-allowed;
}

.transaction-item-container-close-button {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;

  .transaction-hash-container {
    border-radius: 2px;
    white-space: nowrap;
    max-width: 150px;
    text-overflow: ellipsis;
    opacity: 0.7;
    padding: 5px;
    display: flex;
    height: fit-content;
    width: fit-content;
    position: absolute;
    top: -80px;
    left: 0;
    z-index: 99;
    transition: 0.2s ease all;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    transform: translate(200px, 95px);
    &:hover {
      opacity: 1;
      box-shadow: ($expanded-box-shadow);
    }
    font-size: 14px;
    // media queries
    @media only screen and (max-width: 968px) {
      transform: translate(0px, 85px);
    }
  }
  .close-chevron {
    position: sticky;
    left: 50%;
    right: 50%;
  }
}

.transaction-item-container-expanded {
  transition: 0.4 ease all;
  justify-content: flex-start;
  animation: expand 0.25s ease;
  height: ($expanded-report-row-item-height);
  box-shadow: ($expanded-box-shadow);
  @media only screen and (max-width: 1168px) {
    height: ($narrow-expanded-report-row-item-height);
  }

  .transaction-item-container-expanded-section {
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.transaction-item-container-left-section {
  width: fit-content;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @media only screen and (max-width: 968px) {
    gap: 5px;
    padding-right: 10px;
  }
  @media only screen and (max-width: 668px) {
    gap: 5px;
  }
}

.transaction-item-container-middle-section {
  max-width: 80%;
  //min-width: 50%;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  flex-direction: row;
  // media queries
  @media only screen and (max-width: 968px) {
    gap: 5px;
  }
}
.transaction-item-container-right-section {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // media queries
  margin-left: auto;
  @media only screen and (max-width: 968px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: 25px;
  }
}

.report-deposit-container,
.report-withdrawal-container,
.report-trade-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 968px) {
    //scale: 0.8;
    gap: 2px;
  }

  .transaction-badge-container {
    padding: 5px;
    min-width: 120px;
    max-width: 120px;
    display: flex;
    justify-content: center;
    // media queries
    @media only screen and (max-width: 968px) {
      min-width: 80px;
      max-width: 80px;
    }
  }

}

.report-deposit-container,
.report-withdrawal-container {
  width: fit-content;
}


.description-container {
  // a container for the description of the transaction
  // mustn't be too wide and it should break the line if it's too long
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-width: inherit;
  @media only screen and (max-width: 968px) {
    scale: 0.8;
  }
  padding: 4px;
}

.error-fix-button-array {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-width: 500px;
  min-width: 300px;

  .transaction-popover-button-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    padding: 5px;
    .transaction-popover-button-menu-row {
      cursor: pointer;
      label {
        cursor: pointer;
      }
      transition: 0.1s ease all;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: inherit;
      padding: 10px;
      width: 100%;
      border-radius: 8px;
    }
  }
}
#service-menu-fix-button {
  max-width: fit-content;
}

.link-mode-overlay {
  border-radius: 5px;
  box-shadow: ($expanded-box-shadow);
  transform: translateY(2px);
  z-index: 99;
  position: absolute;
  width: 200px;
  height: 100px;
  bottom: 0.8%;
  right: 0.8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  .link-mode-overlay-row {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .link-mode-overlay-row-loader {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.edited-flag-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  .hover {
    display: none;
  }
  &:hover {
    .hover {
      display: flex;
    }
    .non-hover {
      display: none;
    }
  }
}

.link-input-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.tab-bar-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;

  .tab-bar-item,
  .tab-bar-item-active {
    transition: 0.2s ease all;
    opacity: 0.5;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px;
    align-items: center;
    gap: 4px;
    display: flex;

    cursor: pointer;
    * {
      cursor: pointer;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.blank-transaction-item {
  height: ($expanded-report-row-item-height);
}

.row-item-date-container {
  font-size: smaller;
  // media queries
  @media only screen and (max-width: 768px) {
    font-size: x-small;
  }
}

.preview-extras-container {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: auto;
}

.transaction-service-indicator-popover-target {
  display: flex;
  margin-right: 20px;
}

.empty-transactions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 30vh;
  
}

.compact-transactions-popover {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  
}

.txn-hash-label {
  transition: .2s ease all;
  opacity: .5;
  font-size: smaller;
  &:hover {
    opacity: 1;
  }
}