.custom-format-upload-container {
  max-height: 85svh;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  .custom-format-results-container {
    height: 100%;
    min-height: 300px;
    max-height: 55vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 3px;
    border-radius: 4px;

    .converted-transaction-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      height: 100%;
      padding: 4px;
      .converted-transaction-date {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: smaller;
        opacity: 0.7;
        padding: 2px;
      }

      .converted-transaction-type-container {
        cursor: default;
        * {
          cursor: default;
        }
        &.clickable {
          cursor: pointer;
          * {
            cursor: pointer;
          }
          transition: 0.2s ease all;
        }
        padding: 6px;
        border-radius: 8%;
        display: flex;
        align-items: center;
        height: 100%;
        gap: 10px;
      }
    }
  }
}

.empty-custom-input-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  label {
    white-space: wrap;
  }
}
