.menu-container {
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
}

.left-section,
.center-section,
.right-section {
  display: flex;
  align-items: center;
}
.center-section {
  width: 100%;
  justify-content: center;
}

.right-section {
  margin-left: auto;
}

.logo {
  height: 60px;
  padding: 5px;
  padding-left: 20px;
}

.menu-items {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  height: 100%;

  .menu-item,
  .menu-item-active,
  .menu-item-disabled {
    cursor: pointer;
    transition: 0.17s ease all;
    border: 2px solid transparent;
    border-radius: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    .menu-label {
      transition: 0.2s ease all;
      padding: 25px;
      text-transform: uppercase;
      text-decoration-line: none;
      cursor: pointer;
      font-family: "ShareTechRegular";
      label {
        cursor: pointer;
      }
      @media only screen and (max-width: 1040px) {
        padding: 10px;
        font-size: smaller;
      }
    }
  }

  .menu-item-disabled {
    transition-delay: 0.5s;
    display: none;
  }
}

.settings-button-container {
  display: flex;
  width: fit-content;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  height: 100%;
  .settings-button {
    cursor: pointer;
  }
}

.compact-menu-header-container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.menu-label {
  text-decoration-line: none;
  display: flex;
  align-items: center;
  gap: 10px;
  .home-button-container {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: "ShareTechRegular";
    text-transform: uppercase;
    label {
      cursor: pointer;
    }
  }
}

.menu-label {
  display: flex;
  flex-direction: row;
  text-decoration-line: none;
  align-items: center;
  font-size: 18px;
  font-family: "ShareTechRegular";
  text-transform: uppercase;
  padding: 15px;
  .icon-container {
    padding: 10px;
  }
  label {
    cursor: pointer;
  }
}

.authentication-controls-container-compact {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.authentication-menu-control {
  display: flex;
  padding: 5px;
  padding-right: 25px;
  align-items: center;
  .user-label {
    font-family: "ShareTechRegular";
    font-size: 18;
    padding-right: 10px;
  }
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.authentication-menu-item {
  font-family: "ShareTechRegular";
}
