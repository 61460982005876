.header {
  font-size: xx-large;
  // media query
  @media only screen and (max-width: 768px) {
    font-size: larger;
  }
}

.landingpage-body-container {
  .button-row-container {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    * {
      cursor: pointer;
    }

    .landingpage-button-selected {
      .button-label {
        transition: 0.2s ease-in;
        font-size: 28px;
      }
    }
    .landingpage-button {
      .button-label {
        transition: 0.4s ease-out;
        font-size: 20px;
      }
    }
  }
}
