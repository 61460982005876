.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  padding: 10px;
  min-width: 400px;
  max-width: 400px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    min-width: 80vw;
    max-width: 80vw;
  }
}

.login-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.resend-button {
  cursor: pointer;
}
.forgot-password-container {
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  width: fit-content;
  margin-left: auto;
  label {
    cursor: pointer;
  }
}

.reset-form-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.new-password-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .password-reset-container {
    max-width: 600px;
  }
}

.mfa-input {
  border: none;
  font-size: 1.6rem;
  letter-spacing: 2rem;
  text-align: center;
  margin: 0.5rem;
  width: 100%;
  border-radius: 5px;
  outline: none;
  font-family: "ShareTechRegular";
  // hide controls
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  // hide arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.login-registration-form-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}