.report-header-container {
  border: 1px solid;
  display: flex;
  border-radius: 2px;
  flex-direction: row;
  min-height: 150px;
  justify-content: space-between;
  transition: 0.2 ease all;
  //media query
  @media only screen and (max-height: 788px) {
    min-height: 140px;
  }
  @media only screen and (max-width: 768px) {
    min-height: 110px;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.report-filter-menu-container,
.report-center-section-container,
.report-stats-container {
  //media query
  @media only screen and (min-width: 1274px) {
    max-width: 33%;
    min-width: 20%;
  }
  transition: 0.2s ease all;
  width: fit-content;
}

.report-filter-menu-container {
  display: flex;
  flex-direction: column;
  padding: 2px;
  // media query
  @media only screen and (max-width: 1168px) {
    padding: 0px;
    .exchange-filter-selection-container,
    .transaction-type-filter-selection-container,
    .date-interval-filter-selection-container {
      scale: 0.8;
    }
  }
  @media only screen and (max-width: 1068px) {
    padding: 0px;
    .exchange-filter-selection-container,
    .transaction-type-filter-selection-container,
    .date-interval-filter-selection-container {
      scale: 0.78;
    }
  }
  @media only screen and (max-width: 968px) {
    padding: 0px;
    .exchange-filter-selection-container,
    .transaction-type-filter-selection-container,
    .date-interval-filter-selection-container {
      scale: 0.75;
    }
  }
}

.report-stats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (max-width: 768px) {
    min-width: 250px;
  }
  @media screen and (max-width: 620px) {
    min-width: 200px;
  }
  @media screen and (max-width: 520px) {
    min-width: 150px;
  }
  @media screen and (max-width: 420px) {
    min-width: 100px;
  }
  @media screen and (max-width: 320px) {
    min-width: 50px;
  }

  .report-stats-row-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    //media query
    @media only screen and (max-width: 620px) {
      gap: 10px;
    }
    padding: 5px;
    align-items: center;
    .clickable-column {
      transition: 0.2s ease all;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      font-size: smaller;
      .subtitle {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
    .report-stats-header-container {
      padding: 5px;
      min-width: 70px;
    }
    .report-stats-equation-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
      gap: 10px;
      .equation-column {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        * {
          cursor: pointer;
        }
      }
    }
    .result-column-positive,
    .result-column-negative {
      transition: 0.2s ease all;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      padding: 5px;
      border-radius: 2px;
      font-size: larger;
      @media screen and (max-width: 420px) {
        font-size: smaller;
      }
    }
    //media query
    @media only screen and (max-width: 1168px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: smaller;
      .report-stats-row-container {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .report-stats-equation-container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          gap: 10px;
          .equation-column {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
          }
        }
      }
    }
  }
}

.copied-label-container {
  position: absolute;
  top: 4%;
}

.report-center-section-container {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  flex-direction: column;
  .transactions-submit-button {
    cursor: pointer;
    * {
      cursor: pointer;
    }
    width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 5px;
    // media query
    @media only screen and (max-width: 1168px) {
      width: 270px;
      gap: 2px;

      font-size: 12px;
    }
    @media only screen and (max-width: 1068px) {
      width: 250px;
      font-size: 12px;
    }
    .button-icon {
      position: fixed;
      font-size: 22px;
      transform: translate(-120px, 0);
    }

    .submit-button-header {
      font-size: 20px;
      font-weight: 600;
      // media query
      @media only screen and (max-width: 1168px) {
        font-size: 16px;
      }
    }
    .submit-button-body {
      opacity: 0.8;
    }
  }
}

.cancel-updates-container,
.update-errors-container {
  position: absolute;
  transform: translate(0px, 71px);
  transition: 0.2s ease all;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    transform: translate(0px, 20px);
  }
}

.reset-filters-container {
  position: absolute;
  transform: translate(100%, 2px);
  transition: 0.2s ease all;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.exchange-filter-selection-container,
.transaction-type-filter-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.unselected-filter-container,
.selected-filter-container {
  border-radius: 2px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  padding: 5px;
}
.unselected-filter-container {
  padding: 10px;
}

.filter-popover-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  * {
    cursor: pointer;
  }
  .filter-popover-item {
    border: 1px solid transparent;
    padding: 3px;
    border-radius: 2px;
  }

  .filter-platform-item {
    border: 1px solid transparent;
    transition: 0.2s ease all;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.date-interval-filter-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.center-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;

  .more-options-button,
  .more-options-button-disabled {
    transition: 0.2s ease all;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    border: 1px solid transparent;
    border-radius: 3px;
  }
}

.errors-modal-content-container {
  display: flex;
  flex-direction: column;

  .errors-modal-content-scroll-area {
    display: flex;
    flex-direction: column;
  }

  .error-item-row-container {
    display: flex;
    padding: 10px;
    gap: 10px;
  }
}

.compact-report-loading-container {
  height: 100px;
}
