.transaction-left-section {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-width: 25%;
  @media only screen and (max-width: 1168px) {
    gap: 1px;
    padding-right: 1px;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
}

.transaction-outer-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.transaction-in-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  padding: 5px;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    align-items: flex-start;
  }
  // media query
  .transaction-left-section {
    @media only screen and (max-width: 1168px) {
      gap: 1px;
      padding-right: 1px;
      justify-content: flex-start !important;
    }
  }
}

.transaction-out-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  align-items: center;
  padding: 5px;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    align-items: flex-start;
  }

  .transaction-left-section {
    @media only screen and (max-width: 1168px) {
      gap: 1px;
      padding-right: 1px;
      justify-content: flex-start !important;
    }
  }
}

.transaction-fee-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    align-items: flex-start;
  }

  //border: 1px solid blue;
  align-items: center;
  padding: 5px;

  .transaction-left-section {
    @media only screen and (max-width: 1168px) {
      gap: 1px;
      padding-right: 1px;
      justify-content: flex-start !important;
    }
  }
}

.expanded-body-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

.currency-section {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  padding: 3px;
  min-width: 100px;
  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 10px;
  }
}
.sales-section-label {
  //media query
  font-size: larger;
  @media only screen and (max-width: 1500px) {
    font-size: smaller;
  }
}
.sales-section {
  display: flex;
  height: 100%;
  flex-direction: row;
  min-width: 100px;
  justify-content: space-evenly;
  font-size: smaller;
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
    margin-right: 10px;
  }
  .outer-column-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    .column-section,
    .column-section-gain,
    .column-section-loss {
      border-radius: 1px;
      padding: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: right;
    }
  }
}
.history-items-container {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  .outer-history-item-container {
    opacity: 0.5;
    transition: 0.2s ease all;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 3px;
    &:hover {
      opacity: 1;
    }
    .history-item-container {
      display: flex;
      flex-direction: column;
      border: 1px solid transparent;
      transition: 0.2s ease all;
      align-items: center;

      cursor: pointer;
      * {
        cursor: pointer;
      }
      .history-details-tooltip-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        .value-container {
          text-align: right;
        }
      }
    }
  }
}

.transaction-category-label {
  position: relative;
  transform: translateY(-5px);
  top: 0;
  width: 80px;
  // height: 100px;
}

.date-edit-container {
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: fit-content;
  top: 15%;
  right: 2%;
}

.transaction-column {
  display: flex;
  flex-direction: column;
  padding: 5px;
  text-align: center;
  // media query
  @media only screen and (max-width: 1168px) {
    padding: 0px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.transaction-in-out-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}

.currency-section-read-only-outer-container {
  display: flex;
  flex-direction: column;
  .currency-section-read-only-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .original,
    .new,
    .changed {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 2px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
}

.transaction-left-section {
  @media screen and (max-width: 768px) {
    flex: 1 1;
    min-height: 220px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.exchange-rate-description-container {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  font-family: "ShareTechRegular";
  align-items: center;
}

.acquisition-date-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .acquisition-date-description-label {
    margin-top: auto;
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    max-width: 180px;
    font-family: "ShareTechRegular";
    align-items: center;
    opacity: .8;
  }
}

.acquisition-box-divider {
  height: 1px;
  width: 50px;
}