.dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  gap: 50px;
  padding-top: 10px;
  margin-top: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 5px;

  .welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding: 15px;
    height: fit-content;
    transition: 0.2s ease all;
    min-width: 70vw;
    max-width: 90vw;
    flex: 1;

    .welcome-header {
      font-size: larger;
    }

    .announcement-label {
      font-size: smaller;
      opacity: .8;
    }
  }

  .get-started-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding: 15px;
    height: fit-content;
    width: fit-content;
    transition: 0.2s ease all;
    min-width: 700px;
    min-height: 400px;
    flex: 1;

    // media query
    @media only screen and (max-width: 768px) {
      min-width: 65vw;
/*       min-height: 200px;
      max-height: 300px; */
    }

    .get-started-header {
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .get-started-center-content {
      width: 100%;
      .guide-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        .guide-item-container {
          width: 100%;
          flex: 1;
          .guide-gif {
            max-width: 650px;
            @media only screen and (max-width: 768px) {
              max-width: 400px;
            }
          }
        }
      }
    }

    .get-started-bottom-content {
      padding: 5px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .integrations-parent-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding: 15px;
    height: fit-content;
    width: fit-content;
    min-height: 400px;
    flex: 1;

    .integrations-container {
      max-width: 300px;
      min-width: 300px;
      // media query
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        min-width: 100%;
      }

      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 15px;

      .platform-columns {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 5px;
        width: 100%;
        height: 100%;
        padding: 5px;
        cursor: default !important; 
        * {
          cursor: default !important;
        }
      }
    }
  }

  .membership-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding: 15px;
    height: fit-content;
    width: fit-content;
    min-width: 300px;
    min-height: 400px;
    flex: 1;

    .membership-header-label {
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .reports-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding: 15px;
    height: fit-content;
    width: fit-content;
    min-width: 500px;
    max-width: 700px;
    //media query
    @media only screen and (max-width: 768px) {
      min-width: 90%;
    }

    .reports-header-label {
      font-size: larger;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    .report-buttons-row-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      gap: 10px;
      padding: 10px;
      font-size: smaller;
      // media query
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 5px;
      }

      .generating-report-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
      }
    }

    .generated-reports-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .generated-report-item-container {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        transition: 0.2s ease all;

        .report-title {
          transition: 0.2s ease all;
          cursor: pointer;
          * {
            cursor: pointer;
          }
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          gap: 5px;
          flex: 1;
          font-size: smaller;
          // media query
          @media only screen and (max-width: 568px) {
            font-size: x-small;
          }
        }

        .report-download-button {
          transition: 0.2s ease all;
          width: 100%;

          border-radius: 5px;
          display: flex;
          width: 30px;
          height: 30px;
          justify-content: center;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
}
