@font-face {
  font-family: "ShareTechRegular";
  src: local("ShareTechRegular"), url(./fonts/ShareTech-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./fonts/OpenSans-VariableFont.ttf) format("truetype");
}
body {
  font-family: "OpenSans";
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
@-webkit-keyframes transformer {
  from {
    -webkit-transform: scale(0.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes transformer {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes NewValueHighlight {
  0% {
    filter: grayscale(0%);
  }
  100% {
    filter: grayscale(100%);
  }
}
