.dropzone-button-container,
.dropzone-button-container-filled {
  width: 99%;
  transition: 0.2s ease all;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

.dropzone-button-container-filled {
  flex-direction: row;
  font-size: 14px;
  
  .right-section,
  .right-section-disabled,
  .center-section,
  .left-section {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .center-section {
    border-right: 1px;
    border-left: 1px;
    width: 80%;
    justify-content: center;
  }
  .left-section,
  .right-section-disabled,
  .right-section {
    width: 10%;
    justify-content: center;
    padding: 0;
  }
}
.upload-zone-input {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
